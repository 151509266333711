import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import React, { useState, useContext } from "react";
import moment from "moment";
import { LanguageContext } from "../../../utils/context";
import CategoriesTitleDisplay from "./CategoriesTitleDisplay";
import NewPostItem from "./NewPostItem";
import "./styles/blog.css";

const RecentPosts = ({ latestArticles, blogdescription, viewMore, posts }) => {
  let now = moment(new Date());
  let sortedPost = posts.sort(
    (post1, post2) =>
      moment.duration(now.diff(post1.publishedAt)).asDays() -
      moment.duration(now.diff(post2.publishedAt)).asDays()
  );
  sortedPost = sortedPost.slice(0, 3);
  return (
    <div>
      <CategoriesTitleDisplay
        blogdescription={blogdescription}
        whatNewAtKimbocare={`whatNewAtKimbocare`}
        categorieTitle={latestArticles}
      />
      <div className="pt-12 py-8 lg:py-16 grid grid-cols-12 gap-4">
        <div
          className="col-span-12 lg:col-span-8 rounded-l-3xl"
          style={{ height: "clamp(30vh, 45vh, 45vh)" }}
        >
          <NewPostItem
            post={sortedPost[0]}
            titleTextSsize="text-xl lg:text-2xl"
          />
        </div>
        <div
          className="col-span-12 lg:col-span-4 sec-two-r-post"
          style={{ height: "clamp(300px, 45vh, 45vh)" }}
        >
          <div className="flex flex-col h-full justify-between">
            <div
              className="rounded-tr-3xl"
              style={{ height: "clamp(15vh, 21.5vh, 22vh)" }}
            >
              <NewPostItem post={sortedPost[1]} titleTextSsize="text-xl" />
            </div>
            <div
              className="rounded-tr-3xl"
              style={{ height: "clamp(15vh, 21.5vh, 22vh)" }}
            >
              <NewPostItem post={sortedPost[2]} titleTextSsize="text-xl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PonctualRecentPostsWithSearch = ({
  searchArticles,
  categories,
  slug,
  mostRecentText,
  posts,
}) => {
  let now = moment(new Date());
  let postInCurrentCategorie = sortPosts(
    filterPostByCategorie(categories, posts, slug)
  );
  let fart = postInCurrentCategorie.filter(
    (post) => moment.duration(now.diff(post.publishedAt)).asDays() <= 650
  );

  if (fart.length == 0)
    fart = posts.filter(
      (post) => moment.duration(now.diff(post.publishedAt)).asDays() <= 365
    );
  else {
    if (fart.length > 5) fart = fart.slice(0, 5);
  }
  const [articles, setArticles] = useState(fart);
  return (
    <div className="lg:sticky lg:top-36">
      <input
        className="w-full rounded-full text-sm italic font-poppins h-12 outline-none px-8 py-4 border"
        style={{ border: "1px solid rgba(220,220,220)" }}
        type="search"
        placeholder={searchArticles}
        onChange={(event) =>
          setArticles((prevArticles) => search(posts, event))
        }
      />
      <div className="mt-12 mb-8 text-gray-s font-poppins">
        {mostRecentText}
      </div>
      <div className="grid grid-cols-1 gap-4 w-full">
        {articles.map((post, index) => (
          <PostItemForSearch key={index} {...post} />
        ))}
      </div>
    </div>
  );
};

export const RecentPostsWithSearch = ({
  searchArticles,
  mostRecentText,
  posts,
}) => {
  let now = moment(new Date());
  let fart = sortPosts(
    posts.filter(
      (post) => moment.duration(now.diff(post.publishedAt)).asDays() <= 180
    )
  );
  if (fart.length == 0)
    fart = posts.filter(
      (post) => moment.duration(now.diff(post.publishedAt)).asDays() <= 365
    );
  const [articles, setArticles] = useState(fart);
  return (
    <div className="lg:sticky lg:top-36">
      <input
        className="w-full rounded-full text-sm italic font-poppins h-12 outline-none px-8 py-4 border"
        style={{ border: "1px solid rgba(220,220,220)" }}
        type="search"
        placeholder={searchArticles}
        onChange={(event) =>
          setArticles((prevArticles) => search(posts, event))
        }
      />
      <div className="mt-12 mb-8 text-gray-s font-poppins">
        {mostRecentText}
      </div>
      <div className="grid grid-cols-1 gap-4 w-full">
        {articles.map((post, index) => (
          <PostItemForSearch key={index} {...post} />
        ))}
      </div>
    </div>
  );
};

function sortPosts(posts) {
  let changed;
  do {
    changed = false;
    for (let i = 0; i < posts.length - 1; i++) {
      if (
        moment(posts[i].publishedAt).isBefore(moment(posts[i + 1].publishedAt))
      ) {
        let tmp = posts[i];
        posts[i] = posts[i + 1];
        posts[i + 1] = tmp;
        changed = true;
      }
    }
  } while (changed);

  return posts;
}

function filterPostByCategorie(categories, posts, slug) {
  let filterPosts = [];
  categories.forEach((cat) => {
    posts.forEach((post) => {
      if (
        post.categories[0].categorieId.toLowerCase().trim() ===
        cat.categorieId.toLowerCase().trim() &&
        post.slug.current !== slug.current
      ) {
        filterPosts.push(post);
      }
    });
  });
  return filterPosts;
}

function iscategorieBelongTo(categories, categorieId) {
  categories.forEach((cat) => {
    return cat.categorieId === categorieId;
  });
}

function search(articles, event) {
  let now = moment(new Date());
  if (event.target.value.trim().length == 0) {
    let fart = articles.filter(
      (post) => moment.duration(now.diff(post.publishedAt)).asDays() <= 180
    );
    if (fart.length == 0)
      fart = articles.filter(
        (post) => moment.duration(now.diff(post.publishedAt)).asDays() <= 365
      );
    else return fart;
  } else
    return articles.filter(({ longtitle }) =>
      longtitle.includes(event.target.value)
    );
}

const PostItemForSearch = ({ longtitle, slug, mainImage, publishedAt }) => {
  const language = useContext(LanguageContext);
  return (
    <div className="flex justify-start mb-4 pb-4 w-full ">

      <SanityImage
        className="w-28 h-28 mr-8 rounded-md"
        {...mainImage}
        alt={longtitle}
        loading="lazy"
        style={{ objectFit: "cover" }}
      />

      <div className="flex flex-col">
        <span className="text-gray-s text-md font-poppins mb-2">
          {formatDate(publishedAt, language)}
        </span>
        {language === "en" ? (
          <Link to={`/blog/${slug.current}`}>
            <h3
              className="text-black font-poppins cursor-pointer"
              style={articlePostTitletyle}
            >
              {longtitle}
            </h3>
          </Link>
        ) : (
          <Link to={`/fr/blog/${slug.current}`}>
            <h3
              className="text-black font-poppins cursor-pointer"
              style={articlePostTitletyle}
            >
              {longtitle}
            </h3>
          </Link>
        )}
      </div>
    </div>
  );
};

function formatDate(string, language) {
  var options = { year: "numeric", month: "long", day: "numeric" };
  return language === "en"
    ? new Date(string).toLocaleDateString("en-US", options)
    : new Date(string).toLocaleDateString("fr-FR", options);
}

const articlePostTitletyle = {
  font: "normal normal bold 20px/24px poppins",
};

export default RecentPosts;
