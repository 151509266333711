import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { calcRem } from "../../styles/utils";
import { Button, ButtonSimple, ButtonToPopup } from "../../ui-kit/Button";
import "../../styles/ButtonSection.css";
import RequestDemoDialog from "../../ui-kit/RequestDemoModal/RequestDemoModal";

export function ButtonsSection({
  primaryButton,
  secondaryButton,
  isPrivatePageActive,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isGetStartedOpen, setIsGetStartedOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const handleGetStartedButtonClick = () => {
    setIsGetStartedOpen(true);
  };

  return (
    <div className="bg-light-gray flex justify-center mt-16 md:mt-28 h-85 lg:mx-16">
      <div className="container grid grid-cols-1 md:grid-cols-2">
        <ButtonContainer>
          <Description>{primaryButton.description}</Description>
          <Button href="https://app.kimbocare.com/#/login" target="_blank">
            {primaryButton.label}
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Description>
            {isPrivatePageActive
              ? secondaryButton.description
              : secondaryButton.descriptionBusiness}
          </Description>
          {isPrivatePageActive ? (
            <ButtonToPopup setIsOpen={setIsOpen} outlined>
              {secondaryButton.label}
            </ButtonToPopup>
          ) : (
            <Button href="https://www.kimbocare.com/blog" outlined={true}>
              {secondaryButton.labelBusiness}
            </Button>
          )}
        </ButtonContainer>

        {isGetStartedOpen && (
          <RequestDemoDialog setIsOpen={setIsGetStartedOpen} />
        )}
        <Transition.Root show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-30 inset-0 overflow-y-auto"
            initialFocus={cancelButtonRef}
            onClose={setIsOpen}
          >
            <div
              className="relative pl-4 pt-4 z-30 cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              &#10060;
            </div>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-50 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="absolute inline-block bg-white w-full lg:w-2/3 xl:w-4/5 top-16 lg:top-0 xl:top-0 right-0 bottom-0 rounded-t-lg lg:rounded-none md:rounded-none xl:rounded-none text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle  modal-wrapper">
                  <iframe
                    src="https://outlook.office365.com/owa/calendar/KimboCareBookacallwithus@kimbocare.com/bookings/"
                    width="100%"
                    height="100%"
                    style={{ border: 0, overflow: 'auto' }}
                    title="KimboCare Book a Call with Us Calendar"
                  />
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}

function ButtonContainer({ children }) {
  return (
    <div className="flex flex-col justify-center items-center gap-y-6">
      {children}
    </div>
  );
}

const descriptionStyle = {
  lineHeight: calcRem(35),
};

function Description({ children }) {
  return (
    <div
      className="text-black font-poppins text-lg md:text-xl"
      style={descriptionStyle}
    >
      {children}
    </div>
  );
}
