import classNames from 'classnames'
import React from 'react'
import { calcRem } from '../styles/utils'

export function Button({
  children,
  className,
  outlined = false,
  href,
  target,
  isPopup,
}) {
  const classes = classNames(
    "px-11 text-lg text-center tracking-wider md:text-2xl h-14 md:h-19",
    "flex justify-center items-center bg-transparent rounded-full font-poppins font-semibold",
    {
      "bg-kimbo-gradient bg-no-repeat bg-origin-padding bg-clip-padding text-white":
        !outlined,
      "text-[#007566] border-2": outlined,
    },
    className
  )

  return (
    <a
      href={href}
      style={{
        ...(!outlined && {
          boxShadow: `${calcRem(0)} ${calcRem(10)} ${calcRem(30)} #00A8935C`,
        }),
      }}
      className={classNames(classes)}
      target={target}
    >
      {children}
    </a>
  )
}

export function ButtonToPopup({
  children,
  className,
  outlined = false,
  href,
  setIsOpen,
}) {
  const classes = classNames(
    "px-11 text-lg text-center tracking-wider md:text-2xl h-14 md:h-19",
    "flex justify-center items-center bg-transparent rounded-full font-poppins font-semibold",
    {
      "bg-kimbo-gradient bg-no-repeat bg-origin-padding bg-clip-padding cursor-pointer text-white":
        !outlined,
      "text-[#007566] border-2": outlined,
    },
    className
  )

  return (
    <button
      href={href}
      style={{
        ...(!outlined && {
          boxShadow: `${calcRem(0)} ${calcRem(10)} ${calcRem(30)} #00A8935C`,
        }),
      }}
      className={classNames(classes)}
      onClick={() => setIsOpen(true)}
    >
      {children}
    </button>
  )
}

export function ButtonBlueShape({ children, className }) {
  const classes = classNames(
    "w-fit text-white px-11 text-center tracking-wider h-14 md:h-19",
    "flex justify-center items-center rounded-full font-poppins font-semibold",
    className
  )

  return (
    <a
      style={{ background: '#166EE8 0% 0% no-repeat padding-box' }}
      className={classNames(classes)}
    >
      {children}
    </a>
  );
}
